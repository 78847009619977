<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "../../../services/Teams";

export default {
  page: {
    title: "Competition Fixtures",
    meta: [
      {
        name: "Competition Fixtures",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      selectedRound: 1,
      fixtures: null,
      competition: "Eredivisie",

      items: [
        {
          text: "Admin",
        },
        {
          text: "Competition Fixtures",
          active: true,
        },
      ],
    };
  },
  methods: {
    getRounds(comp) {
      Teams.competitionFixtures(comp).then((response) => {
        this.fixtures = response;
      });
    },
    changeCompetition() {
      if (this.competition === "Eredivisie") {
        this.selectedRound = 1
        this.getRounds(2)
      }

      if (this.competition === "Jupiler Pro League") {

        this.selectedRound = 1
        this.getRounds(0)
      }

      if (this.competition === "TOTO KNVB Beker") {
        
        this.selectedRound = 1
        this,this.getRounds(4)
      }
    },
  },

  mounted() {
      this.getRounds(2)
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="container mt-5">
      <p class="fs-4">Competition:</p>
      <div v-if="this.fixtures != null"  class="dropdown mt-3">
        <select v-model="competition" name="" @change="changeCompetition()" id="" class="form-select">
          <option value="Eredivisie">Eredivisie</option>
          <option value="Jupiler Pro League">Jupiler Pro League</option>
          <option value="TOTO KNVB Beker">TOTO KNVB Beker</option>
        </select>
      </div>
      <p class="mt-3 fs-4">Round:</p>
      <div v-if="this.fixtures != null" class="dropdown mt-3">
        <select v-model="selectedRound" name="" id="" class="form-select">
          <option
            v-for="fx in fixtures.data.data.rounds"
            :key="fx.matches[0].match_id"
            :value="fx.matches[0].round"
            >Round {{ fx.matches[0].round }}</option
          >
        </select>
      </div>
      <div v-if="this.fixtures != null" class="mt-5">
        <div
          v-for="fx in fixtures.data.data.rounds[selectedRound].matches"
          :key="fx.match_id"
        >
          <div class="card" v-if="fx.past != 'FUTURE'">
            <div>
              <div class="py-3">
                <div class="container">
                  <div class="row">
                    <div class="d-flex justify-content-between">
                      <div>
                        {{ fx.competition.competition_name }}
                        {{ fx.competition.season }}
                      </div>
                      <div>Round {{ fx.round }}</div>
                    </div>
                  </div>
                  <div
                    v-if="
                      fx.score.formatted != '' || fx.score.formatted != null
                    "
                    class="row justify-content-center"
                  >
                    {{ fx.date_formatted }}
                  </div>
                  <div class="row  align-items-center g-2">
                    <div class="col">
                      <div class="row justify-content-center">
                        <img class="w-25" :src="fx.home_team_logo" alt="" />
                      </div>
                      <div class="row fs-3 fw-bolder justify-content-center">
                        {{ fx.home_team_short }}
                      </div>
                    </div>
                    <div class="col">
                      <div
                        v-if="
                          fx.score.formatted != '' || fx.score.formatted != null
                        "
                      >
                        <div class="row fs-1  justify-content-center">
                          {{ fx.score.formatted }}
                        </div>
                      </div>
                      <div
                        class="row fs-5  justify-content-center align-items-center"
                        v-else
                      >
                        {{ fx.date_formatted }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="row justify-content-center">
                        <img class="w-25" :src="fx.away_team_logo" alt="" />
                      </div>
                      <div class="row fs-3 fw-bolder justify-content-center">
                        {{ fx.away_team_short }}
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col ">
                      <div class="d-flex justify-content-center">
                        <router-link
                          :to="{ path: `/fixtures/current/${fx.match_id}` }"
                        >
                          <button class="btn btn-primary">
                            Details
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
